import { NgClass, NgStyle, TitleCasePipe } from '@angular/common'
import { Component, computed, input } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'
import { Icon } from 'src/app/common/icons'
import { BadgeComponent } from 'src/app/common/ui/badge/badge.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'
import { TooltipComponent } from 'src/app/common/ui/tooltip/tooltip.component'

import { Sentiment } from '../../types'

export type SentimentIconVariant = 'default' | 'text' | 'percentage' | 'percentage-text'

@Component({
  selector: 'sb-sentiment-icon',
  standalone: true,
  imports: [NgStyle, NgClass, TooltipComponent, TextComponent, TranslocoModule, MatIcon, TitleCasePipe, BadgeComponent],
  template: `
    @if (sentiment(); as sentiment) {
      <sb-badge [variant]="badgeVariant()" *transloco="let t">
        <sb-tooltip
          [content]="!variant().includes('text') ? t('sentiment.ui.' + (sentiment | titlecase)) : ''"
          position="above"
        >
          <div
            class="flex w-full items-center gap-1"
            [ngClass]="{ 'justify-center': variant() === 'default', 'justify-between': variant() !== 'default' }"
          >
            <mat-icon [svgIcon]="SentimentIcons[sentiment]" [ngStyle]="{ width: '24px', height: '24px' }" />
            @if (variant().includes('percentage')) {
              <sb-text inline variant="labelLarge" noMargin>{{ percentage() }}%</sb-text>
            }
            @if (variant().includes('text')) {
              <sb-text variant="bodyMedium" [customStyle]="{ lineHeight: 'normal' }" noMargin>{{
                t('sentiment.ui.' + (sentiment | titlecase))
              }}</sb-text>
            }
          </div>
        </sb-tooltip>
      </sb-badge>
    } @else {
      -
    }
  `,
  styles: `
    :host {
      display: contents;
    }
  `,
})
export class SentimentIconComponent {
  sentiment = input<Sentiment>()
  percentage = input<number>()
  variant = input<SentimentIconVariant>('default')
  badgeVariant = computed(() => {
    switch (this.sentiment()) {
      case 'positive':
        return 'success'
      case 'neutral':
        return 'warning'
      case 'negative':
        return 'error'
      case 'mixed':
        return 'primary'
      case 'unclassified':
        return 'default'
    }
  })
  protected readonly SentimentIcons = {
    positive: Icon.SentimentPositive,
    mixed: Icon.SentimentMixed,
    neutral: Icon.SentimentNeutral,
    negative: Icon.SentimentNegative,
    unclassified: Icon.SentimentUnclassified,
  }
}
