import { NgStyle } from '@angular/common'
import { Component, inject, input } from '@angular/core'
import { TranslocoModule } from '@ngneat/transloco'
import { injectQuery } from '@tanstack/angular-query-experimental'
import { lastValueFrom } from 'rxjs'

import { LoaderComponent } from '~common/components/loader/loader.component'
import { AlertComponent } from '~common/ui/alert/alert.component'
import { CommentsApi } from '~features/comments/services/comments.api'

import { Comment } from '../../types/comments'
import { CommentThreadItemComponent } from '../comment-item/comment-thread-item.component'

@Component({
  selector: 'sb-comment-thread',
  standalone: true,
  imports: [TranslocoModule, CommentThreadItemComponent, LoaderComponent, AlertComponent, NgStyle],
  template: `
    <div
      *transloco="let t"
      class="flex flex-col items-center gap-8 p-8"
      [ngStyle]="{ width: '800px', maxWidth: '100%' }"
    >
      @if (threadQuery.isLoading()) {
        <sb-loader class="mt-8" />
      } @else {
        @if (threadQuery.isSuccess()) {
          <sb-comment-thread-item [comment]="threadQuery.data().data" />
          <div class="flex w-full flex-col gap-8 pl-20">
            @for (reply of threadQuery.data().data.replies; track reply) {
              <sb-comment-thread-item [comment]="reply" />
            } @empty {
              <sb-alert type="info">{{ t('comments.info.NoCommentRepliesFound') }}</sb-alert>
            }
          </div>
        } @else if (threadQuery.isError()) {
          <sb-alert type="error">{{ threadQuery.error().message }}</sb-alert>
        }
      }
    </div>
  `,
  styles: ``,
})
export class CommentThreadComponent {
  #api = inject(CommentsApi)
  commentId = input<Comment['id']>()

  threadQuery = injectQuery(() => ({
    queryKey: ['comment-thread', this.commentId()],
    queryFn: () => lastValueFrom(this.#api.getCommentThread(this.commentId())),
  }))
}
