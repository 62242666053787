import { TitleCasePipe } from '@angular/common'
import { Component, Inject, OnInit, inject, output } from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { TranslocoModule } from '@ngneat/transloco'

import { ButtonComponent } from '~common/ui/button/button.component'
import { DialogComponent } from '~common/ui/dialog/dialog.component'
import logger from '~core/utils/logger'

import { CommentFilters } from '../../types'

@Component({
  selector: 'sb-comments-filter-form',
  standalone: true,
  imports: [
    TranslocoModule,
    DialogComponent,
    TitleCasePipe,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  template: `
    <form [formGroup]="form" (ngSubmit)="onSaveFilters()">
      <ng-container *transloco="let t">
        <sb-dialog [title]="t('comments.ui.FilterComments')">
          <ng-template #content>
            <div class="grid grid-cols-1 gap-x-4 gap-y-0 py-5 lg:grid-cols-2">
              <mat-form-field class="lg:col-span-2" appearance="fill">
                <mat-label>{{ t('comments.ui.SearchByAuthor') }}</mat-label>
                <input formControlName="authorName" matInput type="text" />
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>{{ t('comments.fields.sentiment') }}</mat-label>
                <mat-select formControlName="sentiment" multiple>
                  @for (sentiment of sentiments; track sentiment) {
                    <mat-option [value]="sentiment">
                      {{ t('sentiment.ui.' + (sentiment | titlecase)) }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>{{ t('comments.fields.isHidden') }}</mat-label>
                <mat-select formControlName="isHidden">
                  @for (op of booleanOptions; track op) {
                    <mat-option [value]="op">
                      {{ op === null ? t('common.ui.All') : op ? t('common.ui.Yes') : t('common.ui.No') }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>{{ t('comments.fields.isBookmarked') }}</mat-label>
                <mat-select formControlName="isBookmarked">
                  @for (op of booleanOptions; track op) {
                    <mat-option [value]="op">
                      {{ op === null ? t('common.ui.All') : op ? t('common.ui.Yes') : t('common.ui.No') }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>{{ t('comments.ui.YouHaveReplied') }}</mat-label>
                <mat-select formControlName="youHaveReplied">
                  @for (op of booleanOptions; track op) {
                    <mat-option [value]="op">
                      {{ op === null ? t('common.ui.All') : op ? t('common.ui.Yes') : t('common.ui.No') }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </ng-template>
          <ng-template #actions>
            <sb-button variant="text" (click)="dialogRef.close()">{{ t('actions.Cancel') }}</sb-button>
            <sb-button type="submit">{{ t('actions.Apply') }}</sb-button>
          </ng-template>
        </sb-dialog>
      </ng-container>
    </form>
  `,
  styles: ``,
})
export class CommentsFilterFormComponent implements OnInit {
  filters: CommentFilters
  filtersSubmit = output<CommentFilters>()
  form: FormGroup
  #fb = inject(FormBuilder)
  sentiments = ['POSITIVE', 'MIXED', 'NEUTRAL', 'NEGATIVE', 'UNCLASSIFIED']
  booleanOptions = [null, true, false]

  constructor(
    public dialogRef: MatDialogRef<CommentsFilterFormComponent>,
    @Inject(MAT_DIALOG_DATA) data: { filters: CommentFilters },
  ) {
    this.filters = data?.filters
  }

  ngOnInit(): void {
    this.initForm()
  }

  onSaveFilters() {
    // submit
    logger.debug('onSaveFilters', this.form.value)
    this.filtersSubmit.emit(this.form.value)
  }

  private initForm() {
    logger.debug('initForm', this.filters)
    this.form = this.#fb.group({
      authorName: [this.filters?.authorName],
      sentiment: [this.filters?.sentiment],
      isHidden: [this.filters?.isHidden],
      isBookmarked: [this.filters?.isBookmarked],
      youHaveReplied: [this.filters?.youHaveReplied],
    })
  }
}
