import {
  ChangeDetectionStrategy,
  Component,
  booleanAttribute,
  computed,
  inject,
  input,
  model,
  signal,
} from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { TranslocoModule } from '@ngneat/transloco'
import { Store } from '@ngrx/store'
import { injectInfiniteQuery } from '@tanstack/angular-query-experimental'
import { is, isNil, pluck } from 'ramda'
import { lastValueFrom } from 'rxjs'

import { InfiniteScrollComponent } from '~common/components/infinite-scroll/infinite-scroll.component'
import { LoaderComponent } from '~common/components/loader/loader.component'
import { AlertComponent } from '~common/ui/alert/alert.component'
import { UserService } from '~core/services'
import { ScreenSize } from '~core/services/ui/screen.service'
import { selectIsScreenSizeGte } from '~core/store/ui/ui.selectors'
import {
  addFilterBoolEquals,
  addFilterDateBetweenInclusive,
  addFilterGte,
  addFilterIContains,
  addFilterEquals,
  addFilterStringEquals,
  addFilterStringIn,
  filterByFromFilters,
  filterBoolNotEquals,
  filterStringNotEquals,
  addAndFilters,
} from '~core/utils/api'
import logger from '~core/utils/logger'
import { CommentsApi } from '~features/comments/services/comments.api'
import { CommentFilters } from '~features/comments/types'

import { Comment } from '../../types'
import { CommentItemSkeletonComponent } from '../comment-item-skeleton/comment-item-skeleton.component'
import { CommentItemComponent } from '../comment-item/comment-item.component'
import { CommentListToolbarComponent } from '../comment-list-toolbar/comment-list-toolbar.component'

@Component({
  selector: 'sb-comment-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommentItemComponent,
    CommentListToolbarComponent,
    AlertComponent,
    TranslocoModule,
    LoaderComponent,
    InfiniteScrollComponent,
    CommentItemSkeletonComponent,
  ],
  template: `
    <sb-comment-list-toolbar [filters]="toolbarFilters()" (filtersChange)="setToolbarFilters($event)" />
    <div *transloco="let t" class="grid w-full grid-cols-{{ columnCount() }} gap-8">
      @switch (commentsQuery.status()) {
        @case ('pending') {
          @for (i of columns(); track i) {
            <sb-comment-item-skeleton />
          }
        }
        @case ('error') {
          <sb-alert class="col-span-3">{{ t('comments.info.NoCommentsFound') }}</sb-alert>
        }
        @default {
          @for (commentCol of commentColumns(); track commentCol) {
            @if (commentCol.length > 0) {
              <div class="flex flex-col gap-8">
                @for (comment of commentCol; track comment.id) {
                  <sb-comment-item
                    [comment]="comment"
                    [selectable]="selectable()"
                    [(selected)]="selected"
                    [hideActions]="hideActions()"
                    [hideThreadToggle]="hideThreadToggle()"
                  />
                }
                @if (commentsQuery.isPending() || commentsQuery.isFetchingNextPage()) {
                  <sb-comment-item-skeleton />
                }
              </div>
            }
          }

          @if (commentColumns()[0].length === 0) {
            <sb-alert class="col-span-3">{{ t('comments.info.NoCommentsFound') }}</sb-alert>
          }
        }
      }
    </div>
    <sb-infinite-scroll (loadMore)="onLoadMore()" />
  `,
  styles: ``,
})
export class CommentListComponent {
  #commentsApi = inject(CommentsApi)
  initialFilters = input.required<CommentFilters>()
  hideThreadToggle = input(false, { transform: booleanAttribute })
  hideActions = input(false, { transform: booleanAttribute })
  selectable = input(false, { transform: booleanAttribute })
  selected = model<Comment>()
  columns = computed(() => new Array(this.columnCount()))
  toolbarFilters = signal<CommentFilters>({
    orderBy: 'publishedAt:-1',
    showChannelComments: true,
  })
  #store = inject(Store)
  isScreenSmUp = toSignal(this.#store.select(selectIsScreenSizeGte(ScreenSize.Small)))
  isScreenLgUp = toSignal(this.#store.select(selectIsScreenSizeGte(ScreenSize.Large)))
  #userService = inject(UserService)

  columnCount = computed(() => {
    if (this.isScreenLgUp()) {
      return 3
    } else if (this.isScreenSmUp()) {
      return 2
    } else {
      return 1
    }
  })

  commentFilters = computed(() => {
    logger.debug('commentFilters', this.initialFilters(), this.toolbarFilters())
    const queryFilters = []
    addFilterIContains(queryFilters, 'message', this.toolbarFilters().message)
    addFilterIContains(queryFilters, 'authorName', this.toolbarFilters().authorName)
    addFilterStringIn(queryFilters, 'sentiment.sentiment', this.toolbarFilters().sentiment)
    addFilterBoolEquals(queryFilters, 'isBookmarked', this.toolbarFilters().isBookmarked)
    addFilterBoolEquals(queryFilters, 'isHidden', this.toolbarFilters().isHidden)

    if (!isNil(this.toolbarFilters().youHaveReplied)) {
      if (this.toolbarFilters().youHaveReplied) {
        addFilterGte(queryFilters, 'channelReplyCount', 1)
      } else {
        addFilterEquals(queryFilters, 'channelReplyCount', 0)
        addFilterEquals(queryFilters, 'parentId', null) // show only root comments
      }
    }

    if (!this.toolbarFilters().showChannelComments) {
      const currentChannel = this.#userService.getCurrentChannel()
      addAndFilters(queryFilters, [
        filterStringNotEquals('authorId', currentChannel.instagramId ?? currentChannel.facebookId),
        filterBoolNotEquals('authorIsOwner', true),
      ])
    }

    Object.entries(this.initialFilters()).forEach(([key, value]) => {
      if (key === 'since' || key === 'until') {
        return
      } else if (is(String, value)) {
        addFilterStringEquals(queryFilters, key, value)
      } else if (typeof value === 'boolean') {
        addFilterBoolEquals(queryFilters, key, value)
      } else {
        addFilterStringIn(queryFilters, key, value as string[])
      }
    })

    if (this.initialFilters().since && this.initialFilters().until) {
      addFilterDateBetweenInclusive(
        queryFilters,
        'publishedAt',
        this.initialFilters().since,
        this.initialFilters().until,
      )
    }

    return {
      base: {
        pageSize: 15,
        orderBy: 'publishedAt:-1',
      },
      filters: {
        ...filterByFromFilters(queryFilters),
        orderBy: this.toolbarFilters().orderBy,
      },
    }
  })

  commentsQuery = injectInfiniteQuery(() => ({
    queryKey: ['comments', this.commentFilters()],
    queryFn: ({ pageParam: pageNumber }) => {
      return lastValueFrom(
        this.#commentsApi.getComments({
          ...this.commentFilters().base,
          ...this.commentFilters().filters,
          pageNumber,
        }),
      )
    },
    initialPageParam: 1,
    getPreviousPageParam: (firstPage) => (firstPage.meta.currentPage > 1 ? firstPage.meta.currentPage - 1 : undefined),
    getNextPageParam: (lastPage) =>
      lastPage.meta.lastPage > lastPage.meta.currentPage ? lastPage.meta.currentPage + 1 : undefined,
  }))

  // split comments into columns based on column number
  commentColumns = computed<Comment[][]>(() => {
    const data: Comment[][] = []

    if (this.commentsQuery.data() && this.columnCount()) {
      const flatComments = pluck('data', this.commentsQuery.data().pages).flat()
      for (let i = 0; i < this.columnCount(); i++) {
        data.push(flatComments.filter((_, index) => index % this.columnCount() === i))
      }
    }

    return data
  })

  onLoadMore = () => {
    if (this.commentsQuery.isFetching() || !this.commentsQuery.hasNextPage()) return
    this.commentsQuery.fetchNextPage()
  }

  setToolbarFilters = (filters: CommentFilters) => {
    this.toolbarFilters.set(filters)
  }
}
